import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { BusCreatePageName, BusPageName } from '@assets/constants/route-names';
import { defineStore } from 'pinia';
import { CompanyServices } from '@services/company-services';
import { busSerializePayload } from '@stores/bus-profile/bus-serialize-payload';
import { busParsePayload } from '@stores/bus-profile/bus-parse-payload';
import { useInterface } from '@stores';
import { EventLogger } from '@utils/event-metrics';
import { notEmpty } from '@utils/validations/not-empty';

// Store para o componente
// BusProfile e seus componentes filhos
const ONIBUS_HOMOLOGADO_STATUS = ['aprovado', 'aprovado com pendências'];

export const useBusProfileStore = defineStore('BusProfileStore', {
  state: () => ({
    loading: false,
    bus: {
      previewImage: '',
      originalImage: null,
      newImage: null,
      standardizations: null,
      name: null,
      type: null,
      businessType: null,
      licensePlate: null,
      state: null,
      renavam: null,
      chassi: null,
      numberOfAxles: null,
      engine: null,
      yearOfManufacture: null,
      modelYear: null,
      hasBathRoom: false,
      available: true,
      bodyWork: null,
      licenses: null,
      features: null,
      seatTypesIndividual: {
        'executivo individual': {
          name: 'Executivo',
          selected: false,
          capacidade: 0,
        },
        'semi leito individual': {
          name: 'Semi Leito',
          selected: false,
          capacidade: 0,
        },
        'leito individual': {
          name: 'Leito',
          selected: false,
          capacidade: 0,
        },
        'leito cama individual': {
          name: 'Leito Cama',
          selected: false,
          capacidade: 0,
        },
        'cama premium individual': {
          name: 'Cama Premium',
          selected: false,
          capacidade: 0,
        },
      },
      seatTypesCommon: {
        executivo: {
          name: 'Executivo',
          selected: false,
          capacidade: 0,
        },
        'semi leito': {
          name: 'Semi Leito',
          selected: false,
          capacidade: 0,
        },
        leito: {
          name: 'Leito',
          selected: false,
          capacidade: 0,
        },
        'leito cama': {
          name: 'Leito Cama',
          selected: false,
          capacidade: 0,
        },
        'cama premium': {
          name: 'Cama Premium',
          selected: false,
          capacidade: 0,
        },
      },
      documents: {
        crlv: {
          image: null,
          expirationDate: null,
        },
        insurance: {
          image: null,
          expirationDate: null,
        },
        cvs: {
          image: null,
          expirationDate: null,
        },
      },
      isBusProfileFormInvalid: false,

    },
    originalOnibusPayload: null,
  }),
  getters: {
    isDocumentsInvalid(state) {
      const crlv = state.bus?.documents?.crlv;
      const insurance = state.bus?.documents?.insurance;
      // const cvs = $this.onibus?.documents?.cvs;

      const isCRLVInvalid = !notEmpty(crlv?.image) || !notEmpty(crlv?.expirationDate);

      const isInsuranceVInvalid = !notEmpty(insurance?.image)
              || !notEmpty(insurance?.expirationDate);

      // const isCVSInvalid = !notEmpty(cvs?.image);

      return isCRLVInvalid || isInsuranceVInvalid;
    },
    busWasAlreadyHomologado(state) {
      if (!state.bus) {
        return false;
      }
      return ONIBUS_HOMOLOGADO_STATUS.includes(state.bus.status);
    },
    isBusInvalid(state) { return state.bus.isDocumentsInvalid; },
  },
  actions: {
    setLoading(isLoading) {
      this.loading = isLoading;
    },
    async fetchBus(busId) {
      await CompanyServices.getCompanyBus(Number(busId)).then((bus) => {
        this.originalOnibusPayload = bus;
        this.busParsePayload(bus);
      });
    },
    saveBus() {
      const $interfaceState = useInterface();
      this.setLoading(true);

      const documentsFileData = busSerializePayload(this.originalOnibusPayload, this.bus);
      const saveFn = this.bus.id ? CompanyServices.updateBus : CompanyServices.createBus;
      saveFn(documentsFileData)
        .then(() => {
          const term = this.bus?.id ? 'atualizado' : 'criado';
          $interfaceState.toast.add({
            label: `Ônibus ${this.bus.licensePlate} ${term} com sucesso!`,
            color: 'success',
          });

          let event;
          if (this.bus?.id) event = EVENTS_DICTIONARY[BusPageName].busUpdated;
          else event = EVENTS_DICTIONARY[BusCreatePageName].busCreated;

          EventLogger.logEvent(event, { busId: this.bus?.id });
        })
        .catch((error) => {
          const term = this.bus?.id ? 'atualizar' : 'criar';
          $interfaceState.toast.add({
            label: error || `Erro ao ${term} ônibus`,
            color: 'danger',
          });
        })
        .finally(() => {
          this.setLoading(false);
        });
    },
    busParsePayload,
    busSerializePayload,
  },
});
